$(document).ready(function () {
    // footer
    if ($(window).width() <= 768) {
        $('.footer-wrapper > .flex-row:nth-of-type(1) > div h4').click(function () {
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
            } else {
                $('.footer-wrapper > .flex-row:nth-of-type(1) > div h4.open').removeClass('open');
                $(this).addClass('open');
            }
        });
    }
    $('#show-more').click(function (e) {
        e.preventDefault();
        $('#hidden').toggle();
        $(this).text() === 'Περισσότερα' ? $(this).text('Λιγότερα') : $(this).text('Περισσότερα');
    });
});
